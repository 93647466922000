.errorPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  strong {
    color: #45494d;
    display: block;
    font-size: 22px;
    line-height: 25px;
    padding: 24px 0 12px;
  }

  p {
    text-align: center;
    margin: 0 0 32px;
  }
}

@media (max-width: 1023px) {
  .sign {
    width: 118px;
    height: auto;
  }
}

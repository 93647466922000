.dropdown {
  :global {
    .control {
      border-width: 0;

      .material-symbols-outlined {
        display: block;
      }
    }

    .value {
      padding: 0;
    }

    .option {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #252627;

      img {
        margin-right: 8px;
        border-radius: 6px;
      }
    }
  }
}

[dir='rtl'] {
  .dropdown {
    :global {
      img {
        margin: 0 0 0 8px;
      }
    }
  }
}

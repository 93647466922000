.spinnerContainer {
  width: 30px;
  height: 30px;
  position: relative;
}

.spinner {
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0090ff;
}

.fullPage {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 100px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .spinner {
    animation: spin infinite 1.5s linear;
    -webkit-animation: spin infinite 1.5s linear;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  background: #fff;
  font-size: 16px;
  line-height: 20px;
  color: #5f6d76;
}

.App {
  font-size: 13px;
  line-height: 16px;
  color: #5f6d76;
}

a {
  color: #0090ff;
}

@media (prefers-reduced-motion: no-preference) {
  .spinner {
    animation: spin infinite 20s linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

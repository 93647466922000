.btn {
  min-width: 200px;
  border: none;
  background: #66bdff;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:active {
    background: #0090ff;
  }

  &:disabled {
    opacity: 0.6;
    background: #d0d4d7;
  }
}

.cancel {
  composes: btn;
  border: 1px solid #d0d4d7;
  background: #fff;
  color: #eb5757;

  &:active {
    border-color: #eb5757;
    background: #f5f5f5;
  }
}

.full-width {
  composes: btn;
  width: 100%;
}

.secondary {
  composes: btn;
  border: 1px solid #d0d4d7;
  background: #fff;
  color: #252627;

  &:active {
    border-color: #252627;
    background: #f5f5f5;
  }
}

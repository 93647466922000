.infoPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.content {
  max-width: 510px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 90px 24px;
  margin: 0 auto;
  text-align: center;

  > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.platformInfo {
  margin-bottom: 93px;

  strong {
    color: #45494d;
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  div {
    font-size: 18px;
    line-height: 24px;
  }
}

.requestedBy {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  color: #252627;
  margin-bottom: 48px;
}

.reqDetails {
  background: #f0f5fa;
  border-radius: 16px;
  padding: 24px 25px 32px;
  margin: 0 auto 30px;

  strong {
    color: #45494d;
    font-size: 22px;
    line-height: 28px;
    color: #0090ff;
    display: block;
    margin-bottom: 8px;
  }

  div {
    font-size: 16px;
    line-height: 20px;
    color: #76858f;
  }
}

.poweredBy {
  padding-top: 90px;
  color: #76858f;
  font-size: 18px;
  line-height: 24px;

  div {
    margin-bottom: 8px;
  }

  img {
    display: block;
    width: 124px;
    height: auto;
    margin: 0 auto;
  }
}

.warning {
  font-size: 11px;
  line-height: 14px;
  color: #76858f;
}

@media (max-width: 1023px) {
  .sign {
    width: 118px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .content {
    max-width: 100%;
  }
}

@media (max-width: 429px) {
  .content {
    padding: 20px 24px;
  }

  .platformInfo {
    margin-bottom: 20px;

    strong {
      font-size: 20px;
      line-height: 22px;
    }
    div {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .requestedBy {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  .reqDetails {
    padding: 16px 23px 24px;

    div {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .poweredBy {
    font-size: 14px;
    line-height: 20px;
    padding-top: 20px;

    img {
      width: 55%;
    }
  }
}

.header {
  box-sizing: border-box;
  padding: 32px 48px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@media (max-width: 1023px) {
  .header {
    padding: 24px;
  }
}

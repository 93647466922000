.success {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  text-align: center;

  strong {
    color: #45494d;
    font-size: 22px;
    line-height: 25px;
    padding: 24px 0;
  }

  p {
    margin: 0 0 40px;
  }

  .iconPdf {
    margin-right: 3px;
  }
}

@media (max-width: 1023px) {
  .sign {
    width: 118px;
    height: auto;
  }
}

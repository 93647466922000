.processingPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 35px;

  .material-symbols-outlined {
    font-size: 60px;
  }

  strong {
    color: #45494d;
    display: block;
    font-size: 22px;
    line-height: 25px;
    padding: 24px 0 32px;
    text-align: center;
  }
}
